import React from "react";
import AniLink from "gatsby-plugin-transition-link/AniLink";

import '../components/layout.css';
import '../styles/questions.css';
import '../styles/global.css';

const NotFoundPage = () => (
  <div id="containerQuestions">
    <h1 className="number-404">404</h1>
    <div class="content">
      <p className="text-404">
        Pagina não encontrada :(
      </p>
      <AniLink paintDrip to="/" duration={1} direction="right" hex="#3ED206">
        <button className="btn-body btn-primary btn-upper">
          Voltar
        </button>
      </AniLink>
    </div>
  </div>
)

export default NotFoundPage
